@import 'src/routes/client/styles/index.scss';

.container {
  position: relative;
  margin-top: vw_d(20);
  width: vw_d(1200);
  padding: vw_d(20);
  border-radius: vw_d(20);
  border: 2px solid;
  background-color: #ffffff;
  display: flex;
  transition: all 0.25s ease;

  @include screen('tablet') {
    height: vw(192);
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    border-radius: vw(24);
    padding: vw(14) vw(12);
    border: 1px solid;
    margin-bottom: vw(21);
    transition: min-height 0.8s;

    html[data-theme='dark'] & {
      background-color: #1e1e1e;
    }
  }

  @include screen('mobile') {
    height: vw(192);
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    border-radius: vw(24);
    padding: vw(14) vw(12);
    border: 1px solid;
    margin-bottom: vw(21);
    transition: min-height 0.8s;

    html[data-theme='dark'] & {
      background-color: #1e1e1e;
    }
  }

  &.is-funded-event {
    background-color: #f3f8f9;

    @include screen('tablet') {
      html[data-theme='dark'] & {
        background-color: #373535;
      }
    }
    @include screen('mobile') {
      html[data-theme='dark'] & {
        background-color: #373535;
      }
    }
  }

  &.is-full-height {
    @include screen('tablet') {
      height: 100%;
    }
    @include screen('mobile') {
      height: 100%;
    }

    .toggler-handler {
      background-color: transparent;

      &:after {
        display: none;
      }

      .arrow-icon {
        top: 40%;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
  }

  .toggler-handler {
    position: absolute;
    right: 0;
    bottom: 0;
    height: vw(44);
    width: 100%;
    background-color: #ffffff;

    html[data-theme='dark'] & {
      background-color: #1e1e1e;
    }

    &.is-funded-styles {
      background-color: rgb(243, 248, 249);

      html[data-theme='dark'] & {
        background-color: #373535;
      }

      &:after {
        // background-image: linear-gradient(to bottom, rgba(243, 248, 249, 0.5), rgb(243, 248, 249));
        background: rgba(243, 248, 249, 0);
        background: -moz-linear-gradient(
          top,
          rgba(243, 248, 249, 0) 0%,
          rgba(243, 248, 249, 0.25) 8%,
          rgba(243, 248, 249, 1) 67%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(243, 248, 249, 0)),
          color-stop(8%, rgba(243, 248, 249, 0.25)),
          color-stop(67%, rgba(243, 248, 249, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(243, 248, 249, 0) 0%,
          rgba(243, 248, 249, 0.25) 8%,
          rgba(243, 248, 249, 1) 67%
        );
        background: -o-linear-gradient(
          top,
          rgba(243, 248, 249, 0) 0%,
          rgba(243, 248, 249, 0.25) 8%,
          rgba(243, 248, 249, 1) 67%
        );
        background: -ms-linear-gradient(
          top,
          rgba(243, 248, 249, 0) 0%,
          rgba(243, 248, 249, 0.25) 8%,
          rgba(243, 248, 249, 1) 67%
        );
        background: linear-gradient(
          to bottom,
          rgba(243, 248, 249, 0) 0%,
          rgba(243, 248, 249, 0.25) 8%,
          rgba(243, 248, 249, 1) 67%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f8f9', endColorstr='#f3f8f9', GradientType=0 );

        html[data-theme='dark'] & {
          background: rgba(55, 53, 53, 0);
          background: -moz-linear-gradient(
            top,
            rgba(55, 53, 53, 0) 0%,
            rgba(55, 53, 53, 0.25) 8%,
            rgba(55, 53, 53, 1) 67%
          );
          background: -webkit-gradient(
            left top,
            left bottom,
            color-stop(0%, rgba(55, 53, 53, 0)),
            color-stop(8%, rgba(55, 53, 53, 0.25)),
            color-stop(67%, rgba(55, 53, 53, 1))
          );
          background: -webkit-linear-gradient(
            top,
            rgba(55, 53, 53, 0) 0%,
            rgba(55, 53, 53, 0.25) 8%,
            rgba(55, 53, 53, 1) 67%
          );
          background: -o-linear-gradient(
            top,
            rgba(55, 53, 53, 0) 0%,
            rgba(55, 53, 53, 0.25) 8%,
            rgba(55, 53, 53, 1) 67%
          );
          background: -ms-linear-gradient(
            top,
            rgba(55, 53, 53, 0) 0%,
            rgba(55, 53, 53, 0.25) 8%,
            rgba(55, 53, 53, 1) 67%
          );
          background: linear-gradient(
            to bottom,
            rgba(55, 53, 53, 0) 0%,
            rgba(55, 53, 53, 0.25) 8%,
            rgba(55, 53, 53, 1) 67%
          );
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#373535', endColorstr='#373535', GradientType=0 );
        }
      }
    }

    .arrow-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: vw(28);
      height: vw(28);
      transition: transform 0.25s;

      svg {
        width: inherit;
        height: inherit;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: vw(-38);
      left: 0;
      width: 100%;
      height: vw(40);
      z-index: 1;
      background: rgba(255, 255, 255, 0);
      background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.25) 8%,
        rgba(255, 255, 255, 1) 67%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(8%, rgba(255, 255, 255, 0.25)),
        color-stop(67%, rgba(255, 255, 255, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.25) 8%,
        rgba(255, 255, 255, 1) 67%
      );
      background: -o-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.25) 8%,
        rgba(255, 255, 255, 1) 67%
      );
      background: -ms-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.25) 8%,
        rgba(255, 255, 255, 1) 67%
      );
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.25) 8%,
        rgba(255, 255, 255, 1) 67%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );

      html[data-theme='dark'] & {
        background: rgba(30, 30, 30, 0);
        background: -moz-linear-gradient(
          top,
          rgba(30, 30, 30, 0) 0%,
          rgba(30, 30, 30, 0.25) 8%,
          rgba(30, 30, 30, 1) 67%
        );
        background: -webkit-gradient(
          left top,
          left bottom,
          color-stop(0%, rgba(30, 30, 30, 0)),
          color-stop(8%, rgba(30, 30, 30, 0.25)),
          color-stop(67%, rgba(30, 30, 30, 1))
        );
        background: -webkit-linear-gradient(
          top,
          rgba(30, 30, 30, 0) 0%,
          rgba(30, 30, 30, 0.25) 8%,
          rgba(30, 30, 30, 1) 67%
        );
        background: -o-linear-gradient(top, rgba(30, 30, 30, 0) 0%, rgba(30, 30, 30, 0.25) 8%, rgba(30, 30, 30, 1) 67%);
        background: -ms-linear-gradient(
          top,
          rgba(30, 30, 30, 0) 0%,
          rgba(30, 30, 30, 0.25) 8%,
          rgba(30, 30, 30, 1) 67%
        );
        background: linear-gradient(
          to bottom,
          rgba(30, 30, 30, 0) 0%,
          rgba(30, 30, 30, 0.25) 8%,
          rgba(30, 30, 30, 1) 67%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1e', endColorstr='#1e1e1e', GradientType=0 );
      }
    }
  }

  .btnExternal {
    position: absolute;
    display: block;
    left: vw_d(21);
    bottom: vw_d(22);
    min-width: vw_d(157);
    height: vw_d(34);
    border-radius: vw_d(24);
    font-family: $regular-font;
    font-size: vw_d(18);
    color: #ffffff;
    padding: 0 vw_d(16);
    line-height: vw_d(24);
    padding-top: vw_d(5);
    text-align: center;
    background-color: var(--buttonsColor);

    @include screen('tablet') {
      position: static;
      min-width: vw(157);
      height: vw(34);
      border-radius: vw(24);
      font-size: vw(18);
      line-height: vw(24);
      padding-top: vw(5);
      padding: vw(5) vw(16) 0 vw(16);
      margin: vw(22) auto vw(2);
    }

    @include screen('mobile') {
      position: static;
      min-width: vw(157);
      height: vw(34);
      border-radius: vw(24);
      font-size: vw(18);
      line-height: vw(24);
      padding: vw(5) vw(16) 0 vw(16);
      margin: vw(22) auto vw(2);
    }
  }

  .tags {
    position: absolute;
    top: vw_d(14);
    left: vw_d(21);
    display: flex;

    @include screen('tablet') {
      top: vw(10);
      left: vw(15);
    }
    @include screen('mobile') {
      top: vw(10);
      left: vw(15);
    }

    .tag {
      border-radius: vw_d(15);
      height: vw_d(29);
      min-width: vw_d(78);
      font-family: $light-font;
      font-size: vw_d(20);
      line-height: 1.4;
      letter-spacing: -0.23px;
      text-align: center;
      padding: 0 vw_d(9);
      margin-right: vw_d(14);

      @include screen('tablet') {
        height: vw(22);
        min-width: vw(59);
        border-radius: vw(10);
        font-size: vw(16);
        line-height: 1;
        padding: vw(3) vw(9);
        margin-right: vw(8);
      }
      @include screen('mobile') {
        height: vw(22);
        min-width: vw(59);
        border-radius: vw(10);
        font-size: vw(16);
        line-height: 1;
        padding: vw(3) vw(9);
        margin-right: vw(8);
      }
    }
  }

  .img-wrapper {
    width: vw_d(321);
    height: vw_d(307);

    @include screen('tablet') {
      width: vw(339);
      height: vw(315);
      margin: 0 auto vw(40);
      order: 1;
    }
    @include screen('mobile') {
      width: vw(339);
      height: vw(315);
      margin: 0 auto vw(40);
      order: 1;
    }

    & img {
      object-fit: cover;
      width: inherit;
      height: inherit;
      border-radius: vw_d(14);

      @include screen('tablet') {
        border-radius: vw(15);
      }
      @include screen('mobile') {
        border-radius: vw(15);
      }
    }

    .credits {
      font-size: vw_d(12);
      margin: 0;

      @include screen('tablet') {
        font-size: vw(12);
        padding-top: vw(4);
        padding-right: vw(2);
      }
      @include screen('mobile') {
        font-size: vw(12);
        padding-top: vw(4);
        padding-right: vw(2);
      }
    }
  }

  &-event-data {
    padding: 0 vw_d(20) 0 0;
    width: vw_d(700);
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include screen('tablet') {
      width: 100%;
      padding: 0 vw(5) 0 0;
      order: 0;
    }
    @include screen('mobile') {
      width: 100%;
      padding: 0 vw(5) 0 0;
      order: 0;
    }

    .texts {
      padding-bottom: vw_d(12);

      @include screen('tablet') {
        padding-bottom: vw(16);

        html[data-theme='dark'] & {
          color: #fff;
        }
      }
      @include screen('mobile') {
        padding-bottom: vw(16);

        html[data-theme='dark'] & {
          color: #fff;
        }
      }
    }

    & .title {
      font-family: $medium-font;
      font-size: vw_d(30);
      line-height: 1.33;
      letter-spacing: -0.5px;

      @include screen('tablet') {
        font-size: vw(25);
        line-height: 1.25;
        max-width: 85%;
        padding-top: vw(14);
      }
      @include screen('mobile') {
        font-size: vw(25);
        line-height: 1.25;
        max-width: 85%;
        padding-top: vw(14);
      }
    }

    & .description {
      font-family: $regular-font;
      font-size: vw_d(18);
      line-height: 1.33;
      letter-spacing: -0.3px;
      padding: vw_d(6) vw_d(4) 0 0;

      @include screen('tablet') {
        font-size: vw(18);
        padding: vw(6) vw(4) 0 0;
      }
      @include screen('mobile') {
        font-size: vw(18);
        padding: vw(6) vw(4) 0 0;
      }
    }

    & .information {
      @include screen('tablet') {
        padding-bottom: vw(25);
        margin-right: vw(-5);

        html[data-theme='dark'] & {
          color: #fff;
        }
      }
      @include screen('mobile') {
        padding-bottom: vw(25);
        margin-right: vw(-5);

        html[data-theme='dark'] & {
          color: #fff;
        }
      }
    }

    & .informationItem {
      margin-bottom: vw_d(6);
      display: flex;

      @include screen('tablet') {
        margin-bottom: vw(6);
      }
      @include screen('mobile') {
        margin-bottom: vw(6);
      }
    }

    & .informationItem:last-child {
      margin-bottom: 0;
    }

    & .informationIcon {
      display: inline-flex;
      width: vw_d(24);
      height: vw_d(24);
      justify-content: center;

      @include screen('tablet') {
        width: vw(24);
        height: vw(24);
      }
      @include screen('mobile') {
        width: vw(24);
        height: vw(24);
      }

      svg {
        width: vw_d(24);
        height: vw_d(24);

        @include screen('tablet') {
          width: vw(24);
          height: vw(24);
        }
        @include screen('mobile') {
          width: vw(24);
          height: vw(24);
        }
      }
    }

    & .informationText {
      // display: inline-flex;
      font-size: vw_d(18);
      margin-right: vw_d(10);
      font-weight: 300;
      letter-spacing: -0.3px;
      font-family: $light-font;

      @include screen('tablet') {
        font-size: vw(18);
        margin-right: vw(10);
      }
      @include screen('mobile') {
        font-size: vw(18);
        margin-right: vw(10);
      }
    }
  }
}
